import React from 'react'
// nodejs library that concatenates classes
import classNames from 'classnames'
// core components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'

import officeStyle from 'assets/jss/styles/pages/landing/worksStyle.js'

// office
import office1 from 'assets/img/works/tela.jpg'
import office2 from 'assets/img/works/Bocha-local.jpg'
import office3 from 'assets/img/works/cocina3.jpeg'
import office4 from 'assets/img/works/04b.jpg'
import office5 from 'assets/img/works/008.jpg'

const useStyles = makeStyles(officeStyle)

export default function SectionWorks() {
  const classes = useStyles()
  return (
    <div className={classes.office}>
      <GridContainer className={classes.textCenter}>
        <GridItem
          md={8}
          sm={8}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <h3 className={classes.title}>
            Algunos de nuestros trabajos
          </h3>
          <h4 className={classes.description}>
            Nos avalan años de experiencia y cientos de obras y reformas. Somos especialistas en construcción y reformas de viviendas y locales comerciales.
          </h4>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem md={4} sm={4}>
          <img
            className={classNames(
              classes.imgRaised,
              classes.imgFluid,
              classes.rounded
            )}
            src={office1}
            alt="Reforma integral de un restaurante en primera línea de Sitges, Barcelona."
          />
        </GridItem>
        <GridItem md={4} sm={4}>
          <img
            className={classNames(
              classes.imgRaised,
              classes.imgFluid,
              classes.rounded
            )}
            src={office3}
            alt="Reforma de una cocina en una vivienda en Barcelona."
          />
        </GridItem>
        <GridItem md={4} sm={4}>
          <img
            className={classNames(
              classes.imgRaised,
              classes.imgFluid,
              classes.rounded
            )}
            src={office2}
            alt="Reforma integral del restaurante Bocha en Sitges, Barcelona."
          />
        </GridItem>        
        <GridItem md={6} sm={6}>
          <img
            className={classNames(
              classes.imgRaised,
              classes.imgFluid,
              classes.rounded
            )}
            src={office4}
            alt="Construcción de una vivienda en Barcelona."
          />
        </GridItem>
        <GridItem md={6} sm={6}>
          <img
            className={classNames(
              classes.imgRaised,
              classes.imgFluid,
              classes.rounded
            )}
            src={office5}
            alt="Proyectos de arquitectura e ingeniería."
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}
